import React from "react";
import { PlusSquare, ShoppingCart, Users } from "react-feather";
import { AiOutlineBank } from "react-icons/ai";
import { BiMessageAltCheck } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";

import { HiOutlineDocumentReport } from "react-icons/hi";
import { RiDashboardLine, RiLockPasswordLine } from "react-icons/ri";
import { TiMessages } from "react-icons/ti";
import async from "../components/Async";
import { CalendarIcon } from "@mui/x-date-pickers";
import { FaTasks } from "react-icons/fa";
const Ranking = async(() =>
  import("../pages/relatorio/ranking/Ranking")
);
const RelatorioCarteira = async(() =>
  import("../pages/relatorio/carteiraConsultor/RelatorioCarteira")
);

const ArquivoPagamentoBonus = async(() =>
  import("../pages/cadastro/bonusanual/ArquivoPagamentoBonus")
);
const RelatorioSolicitacaoResgate = async(() =>
  import("../pages/relatorio/atencipacoes/RelatorioSolicitacaoResgate")
);

const RelatorioContratosVencer = async(() =>
  import("../pages/relatorio/contratosVencer/RelatorioContratosVencer")
);
const RelatorioDeEquipe = async(() =>
  import("../pages/relatorio/equipe/RelatorioDeEquipe")
);
const ArvoreHierarquica = async(() =>
  import("../pages/relatorio/arvore/ArvoreHierarquica")
);
const IRPF = async(() =>
  import("../pages/cadastro/irpf")
);
const TokenMFA = async(() => import("../pages/auth/TokenMFA"));
const PesquisarPessoa = async(() => import("../pages/pessoa/PesquisarPessoa"));
const PesquisarCliente = async(() =>
  import("../pages/cliente/PesquisarCliente")
);
const ManterPessoa = async(() => import("../pages/pessoa/ManterPessoa"));
const ManterCliente = async(() => import("../pages/cliente/ManterCliente"));
const Mensagem = async(() => import("../pages/mensagem/Mensagem"));
const ResetPasswordInputSenha = async(() =>
  import("../pages/auth/ResetPasswordInputSenha")
);
const Empresas = async(() => import("../pages/empresa/Empresas"));
const Parametro = async(() => import("../pages/cadastro/parametro/Parametro"));
const Feriado = async(() => import("../pages/cadastro/feriados/Feriado"));
const Contrato = async(() => import("../pages/cadastro/contrato/Contrato"));
const Conciliacao = async(() => import("../pages/cadastro/conciliacao/Conciliacao"));
const Tasks = async(() => import("../pages/tarefas/Tasks"));

const VisualizarContrato = async(() =>
  import("../pages/cadastro/contrato/VisualizarContrato")
);

const irpf = async(() =>
  import("../pages/cadastro/irpf")
);

const AlterarSenha = async(() =>
  import("../pages/cadastro/alterarsenha/AlterarSenha")
);
const RelatorioLogUsuario = async(() =>
  import("../pages/relatorio/logusuario/RelatorioLogUsuario")
);
const Notificacao = async(() =>
  import("../pages/cadastro/notificacao/Notificacao")
);
const RelatorioUsuarios = async(() =>
  import("../pages/relatorio/usuarios/RelatorioUsuarios")
);
const RelatorioComissaoMensalContrato = async(() =>
  import(
    "../pages/relatorio/comissaoMensalContrato/RelatorioComissaoMensalContrato"
  )
);
const RelatorioAssinaturaContratoConsultor = async(() =>
  import(
    "../pages/relatorio/statusAssinaturaContratoConsultor/RelatorioAssinaturaContratoConsultor"
  )
);
const RelatorioCliente = async(() =>
  import("../pages/relatorio/cliente/RelatorioCliente")
);
const RelatorioResgate = async(() =>
  import("../pages/relatorio/resgate/RelatorioResgate")
);
const Empresa = async(() => import("../pages/cadastro/empresa/Empresa"));
const Produto = async(() => import("../pages/cadastro/produto/Produto"));
const Banco = async(() => import("../pages/cadastro/banco/Banco"));
const Classe = async(() => import("../pages/cadastro/classe/Classe"));
const FaixaProducao = async(() =>
  import("../pages/cadastro/faixaProducao/FaixaProducao")
);
const EmpresaGrupo = async(() =>
  import("../pages/cadastro/empresagrupo/EmpresaGrupo")
);
const RelatorioAjudaDeCusto = async(() =>
  import("../pages/relatorio/ajudaDeCusto/RelatorioAjudaDeCusto")
);
const RelatorioTiposCarteiras = async(() =>
  import("../pages/relatorio/carteiraConsultor/RelatorioTiposCarteiras")
);
const VisualizarCarteiraPessoal = async(() =>
  import("../pages/relatorio/carteiraConsultor/VisualizarCarteiraPessoal")
);
const VisualizarCarteiraHerdada = async(() =>
  import("../pages/relatorio/carteiraConsultor/VisualizarCarteiraHerdada")
);
const VisualizarCarteiraParticipacao = async(() =>
  import("../pages/relatorio/carteiraConsultor/VisualizarCarteiraParticipacao")
);
const RelatorioBonusAnual = async(() =>
  import("../pages/relatorio/bonusAnual/RelatorioBonusAnual")
);
const Perfis = async(() => import("../pages/cadastro/perfis/Perfis"));
const ArquivoPagamentoDividendos = async(() =>
  import(
    "../pages/cadastro/arquivoremessadividendos/ArquivoPagamentoDividendos"
  )
);

const ArquivoPagamentoComissoes = async(() =>
  import("../pages/cadastro/arquivocomissoes/ArquivoPagamentoComissoes")
);

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
const SignInCadastro = async(() => import("../pages/auth/SignInCadastro"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const Settings = async(() => import("../pages/manutencao/Settings"));
const Calendar = async(() => import("../pages/pages/Calendar"));
// Protected routes
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

const suporteRoutes = {
  path: "/",
  header: "Suporte",
  permission: [],
  perfil: ["SUPORTE", "FINANCEIRO"],
  children: [],
  component: null,
  guard: AuthGuard,
};

const empresasRoutes = {
  id: "Empresas",
  path: "/empresas",
  perfil: ["SUPORTE", "FINANCEIRO"],
  icon: <AiOutlineBank />,
  permission: [],
  component: Empresas,
  children: null,
  guard: AuthGuard,
};

const mensagensRoutes = {
  id: "Mensagens",
  path: "/mensagens",
  perfil: ["SUPORTE", "FINANCEIRO"],
  icon: <BiMessageAltCheck />,
  permission: [],
  component: Mensagem,
  children: null,
  guard: AuthGuard,
};

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  permission: [],
  header: "Operacional",
  icon: <RiDashboardLine />,
  containsHome: true,
  component: Default,
  guard: AuthGuard,
};

const consultasRoutes = {
  id: "Cadastros",
  path: "/cadastros",
  permission: [
    "manutencao_perfis",
    "manter_parametros",
    "manter_pessoa",
    "manter_banco",
    "manter_classe",
    "manter_produto",
    "manter_empresa_grupo",
    "manter_status_contrato",
    "manter_contrato",
    "gerenciamento_eventos_calendario",
    "manter_faixa_producao",
  ],
  icon: <PlusSquare />,
  guard: AuthGuard,
  children: [
    {
      path: "/consultor/cadastro/:idMutuarioParam",
      hiddenSidebar: true,
      permission: ["manter_pessoa"],
      component: ManterPessoa,
      guard: AuthGuard,
    },
    {
      path: "/contrato/detalhe/:idContratoParam",
      hiddenSidebar: true,
      permission: ["manter_contrato"],
      component: VisualizarContrato,
      guard: AuthGuard,
    },    
    {
      path: "/consultor/cadastro",
      hiddenSidebar: true,
      permission: ["manter_pessoa"],
      component: ManterPessoa,
      guard: AuthGuard,
    },
    {
      path: "/cliente/cadastro/:idMutuarioParam",
      hiddenSidebar: true,
      permission: ["manter_cliente"],
      component: ManterCliente,
      guard: AuthGuard,
    },
    {
      path: "/cliente/cadastro",
      hiddenSidebar: true,
      permission: ["manter_cliente"],
      component: ManterCliente,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/banco",
      name: "Banco",
      permission: ["manter_banco"],
      component: Banco,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/classe",
      name: "Classe",
      permission: ["manter_classe"],
      component: Classe,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/cliente",
      name: "Cliente",
      permission: ["manter_cliente"],
      component: PesquisarCliente,
      guard: AuthGuard,
    },

    {
      path: "/cadastros/parametros",
      name: "Configurações",
      permission: ["manter_parametros"],
      component: Parametro,
      guard: AuthGuard,
    },
    {
      name: "Consultor",
      path: "/consultor/pesquisa",
      permission: ["manter_pessoa"],
      component: PesquisarPessoa,
      guard: AuthGuard,
    },
    {
      name: "Contrato",
      path: "/contrato/pesquisa",
      permission: ["manter_contrato"],
      component: Contrato,
      guard: AuthGuard,
    },
    {
      name: "Conciliação",
      path: "/conciliacao/",
      permission: ["permissao_nova_conciliacao"],
      component: Conciliacao,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/empresa",
      name: "Empresa",
      permission: ["manter_empresa_grupo"],
      component: EmpresaGrupo,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/faixaProducao",
      name: "Faixa de Produção",
      permission: ["manter_faixa_producao"],
      component: FaixaProducao,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/feriados",
      name: "Feriados",
      permission: ["gerenciamento_eventos_calendario"],
      component: Feriado,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/pagamentoDividendos",
      name: "Pagamento Dividendos",
      permission: ["pagamento_dividendos"],
      component: ArquivoPagamentoDividendos,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/pagamentoComissoes",
      name: "Pagamento Comissoes",
      permission: ["pagamento_comissoes"],
      component: ArquivoPagamentoComissoes,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/pagamentoBonus",
      name: "Pagamento do Bônus Anual",
      permission: ["bonus_anual_gera_lote"],
      component: ArquivoPagamentoBonus,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/perfis",
      name: "Perfis",
      permission: ["manutencao_perfis"],
      component: Perfis,
      guard: AuthGuard,
    },
    {
      path: "/cadastros/produto",
      name: "Produto",
      permission: ["manter_produto"],
      component: Produto,
      guard: AuthGuard,
    },
    {
      path: "/irpf/cadastro",
      name: "Informe de rendimentos",
      permission: ["visualizar_tela_irpf"],
      component: IRPF,
      guard: AuthGuard,
    },
  ],
  component: null,
};

const pagesRoutes = {
  id: "Relatórios",
  path: "/relatorios",
  permission: [
    "rel_log_usuario",
    "relatorio_usuarios",
    "rel_arvore_hierarquia",
    "ranking_producao",
    "rel_contratos_vencer",
    "rel_antecipacao_contrato",
  ],
  icon: <HiOutlineDocumentReport />,
  children: [
    {
      path: "/relatorios/ranking",
      name: "Ranking de Produção",
      permission: ["ranking_producao"],
      component: Ranking,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/ajudadecusto",
      name: "Relatório de Ajuda de Custo",
      permission: ["relatorio_ajudaDeCusto"],
      component: RelatorioAjudaDeCusto,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/bonusAnual",
      name: "Relatório Bonus Anual - Calculo Mensal",
      permission: ["bonus_anual_gera_view"],
      component: RelatorioBonusAnual,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/relatorioTiposCarteiras",
      name: "Relatorio de Carteiras",
      permission: ["relatorio_de_tipos_de_carteira"],
      component: RelatorioTiposCarteiras,
      guard: AuthGuard,
    },
    {
      path: "/relatorio/VisualizarCarteiraPessoal/:idPessoaParam/:dataReferenciaPagamento",
      hiddenSidebar: true,
      permission: ["relatorio_de_tipos_de_carteira_detalhamento"],
      component: VisualizarCarteiraPessoal,
      guard: AuthGuard,
   },
   {
    path: "/relatorio/VisualizarCarteiraParticipacao/:idPessoaParam/:dataReferenciaPagamento",
    hiddenSidebar: true,
    permission: ["relatorio_de_tipos_de_carteira_detalhamento"],
    component: VisualizarCarteiraParticipacao,
    guard: AuthGuard,
   },
   {
    path: "/relatorio/VisualizarCarteiraHerdada/:idPessoaParam/:dataReferenciaPagamento",
    hiddenSidebar: true,
    permission: ["relatorio_de_tipos_de_carteira_detalhamento"],
    component: VisualizarCarteiraHerdada,
    guard: AuthGuard,
  },
    {
      path: "/relatorios/equipe",
      name: "Relatório de Equipe",
      permission: ["relatorio_equipe"],
      component: RelatorioDeEquipe,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/antecipacao",
      name: "Relatório de Antecipação de Contrato",
      permission: ["rel_antecipacao_contrato"],
      component: RelatorioSolicitacaoResgate,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/arvore",
      name: "Arvore Hierarquia",
      permission: ["rel_arvore_hierarquia"],
      component: ArvoreHierarquica,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/cliente",
      name: "Relatório de Cliente",
      permission: ["relatorio_cliente"],
      component: RelatorioCliente,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/comissaoMensalContrato",
      name: "Comissão Mensal de Contratos",
      permission: ["relatorio_comissaoMensal"],
      component: RelatorioComissaoMensalContrato,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/statusAssinaturaContratoConsultor",
      name: "Status Assinatura Contrato Consultor",
      permission: ["relatorio_statusAssinaturaContratoConsultor"],
      component: RelatorioAssinaturaContratoConsultor,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/contratosAVencer",
      name: "Contratos a Vencer",
      permission: ["rel_contratos_vencer"],
      component: RelatorioContratosVencer,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/auditoria",
      name: "Log do Usuário",
      permission: ["rel_log_usuario"],
      component: RelatorioLogUsuario,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/producaoconsultor",
      name: "Produção Consultor",
      permission: ["relatorio_home_consultor"],
      component: RelatorioCarteira,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/resgate",
      name: "Relatório Resgate de Contratos",
      permission: ["relatorio_resgate_parcial"],
      component: RelatorioResgate,
      guard: AuthGuard,
    },
    {
      path: "/relatorios/usuarios",
      name: "Usuários",
      permission: ["relatorio_usuarios"],
      component: RelatorioUsuarios,
      guard: AuthGuard,
    },
  ],
  component: null,
};

const empresaRoute = {
  id: "Empresa",
  path: "/empresa",
  permission: [],
  icon: <ShoppingCart />,
  component: Empresa,
  children: null,
  guard: AuthGuard,
};

const authRoutes = {
  id: "Auth",
  path: "/",
  permission: [],
  icon: <Users />,
  children: [
    {
      path: "/",
      name: "Login",
      component: SignIn,
    },
    {
      path: "/auth/posCadastro/:token",
      hiddenSidebar: true,
      component: SignInCadastro,
    },
    {
      path: "/auth/reset-password",
      name: "Esqueci Minha Senha",
      component: ResetPassword,
    },
    {
      path: "/recuperarSenha/:hash",
      name: "Alterar Senha",
      component: ResetPasswordInputSenha,
    },
    {
      path: "/auth/mfa",
      name: "Token MFA",
      component: TokenMFA,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const componentsRoutes = {
  path: "/",
  header: "Produtividade",
  permission: [],
  children: [],
  component: null,
  guard: AuthGuard,
};

const tarefasRoutes = {
  id: "Tarefas",
  path: "/tarefas",
  icon: <FaTasks />,
  permission: [],
  component: Tasks,
  children: null,
  guard: AuthGuard,
};

const notificacaoRoutes = {
  id: "Notificações",
  path: "/notificacao/manter",
  permission: [],
  icon: <TiMessages />,
  component: Notificacao,
  children: null,
  guard: AuthGuard,
};

const meuPontoRoutes = {
  path: "/",
  header: "Minha Área",
  permission: [],
  children: [],
  component: null,
  guard: AuthGuard,
};

const alterarSenha = {
  id: "Alterar Senha",
  permission: [],
  path: "/alterarSenha",
  icon: <RiLockPasswordLine />,
  component: AlterarSenha,
  children: null,
  guard: AuthGuard,
};

const meuPerfil = {
  id: "Meus Dados",
  permission: [],
  path: "/manutencao/profile",
  icon: <CgProfile />,
  component: Settings,
  children: null,
  guard: AuthGuard,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  permission: [],
  path: "/app",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

const calendarioRoutes = {
  id: "Calendário",
  path: "/calendario",
  permission: [],
  icon: <CalendarIcon />,
  component: Calendar,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  consultasRoutes,

  pagesRoutes,
  suporteRoutes,
  empresasRoutes,
  mensagensRoutes,
  empresaRoute,
  componentsRoutes,
  notificacaoRoutes,
  calendarioRoutes,
  tarefasRoutes,
  meuPontoRoutes,
  meuPerfil,
  alterarSenha,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  consultasRoutes,
  pagesRoutes,
  suporteRoutes,
  empresasRoutes,
  mensagensRoutes,
  componentsRoutes,
  notificacaoRoutes,
  calendarioRoutes,
  tarefasRoutes,
  meuPontoRoutes,
  meuPerfil,
  alterarSenha,
];
