//import axios from "../utils/axios";
import Axios from "axios";

function find(idContrato) {
  return new Promise((resolve, reject) => {
    Axios.get(`/contrato/${idContrato}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarContrato(
  consultor,
  cliente,
  produto,
  status,
  cpfCliente,
  codExterno,
  idContrato,
  page,
  dataInicio,
  dataFim,
  contratoNovo,
  contratoRenovado,
  contratoUnificado,
  contratoResgate,
  sortField,
  sortOrder
) {
  return new Promise((resolve, reject) => {
    const vo = {
      // cliente, produto, status, consultor, cpfCliente, codExterno, idContrato, dataInicio: (dataInicio ? dataInicio.toISOString().split('T')[0] : null) , dataFim: (dataFim ? dataFim.toISOString().split('T')[0] : null)
      cliente,
      produto,
      status,
      consultor,
      cpfCliente,
      codExterno,
      idContrato,
      dataInicio,
      dataFim,
      contratoNovo,
      contratoRenovado,
      contratoResgate,
      contratoUnificado,
    };
    Axios.post(
      `/contrato/pesquisa/${page}/${sortField}/${sortOrder}`,
      JSON.stringify(vo)
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarContratoTotais(
  consultor,
  cliente,
  produto,
  status,
  cpfCliente,
  codExterno,
  idContrato,
  dataInicio,
  dataFim,
  contratoNovo,
  contratoRenovado,
  contratoUnificado,
  contratoResgate
) {
  return new Promise((resolve, reject) => {
    const vo = {
      // cliente, produto, status, consultor, cpfCliente, codExterno, idContrato, dataInicio: (dataInicio ? dataInicio.toISOString().split('T')[0] : null) , dataFim: (dataFim ? dataFim.toISOString().split('T')[0] : null)
      cliente,
      produto,
      status,
      consultor,
      cpfCliente,
      codExterno,
      idContrato,
      dataInicio,
      dataFim,
      contratoNovo,
      contratoRenovado,
      contratoUnificado,
      contratoResgate,
    };
    Axios.post(`/contrato/pesquisaTotais`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function contratoVencimento() {
  return new Promise((resolve, reject) => {
    Axios.get(`/contrato/contratoVencimento`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function downloadContratoAssinado(idContrato) {
  return new Promise((resolve, reject) => {
    Axios.get(`/contrato/downloadContratoAssinado/${idContrato}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function preContrato({
  cliente,
  produto,
  aporte,
  dataAporte,
  periodo,
  rentabilidade,
  meioEnvioLinkAssinatura,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      cliente,
      rentabilidade,
      produto,
      aporte,
      dataAporte,
      periodo,
      meioEnvioLinkAssinatura,
    };
    Axios.post(`/contrato/preBoletar`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function createContrato({
  cliente,
  produto,
  aporte,
  dataAporte,
  periodo,
  rentabilidade,
  meioEnvioLinkAssinatura,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      cliente,
      rentabilidade,
      produto,
      aporte,
      dataAporte,
      meioEnvioLinkAssinatura,
      periodo,
    };
    Axios.post(`/contrato`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function confirmarPreContrato({
  produto,
  aporte,
  dataAporte,
  periodo,
  rentabilidade,
  idContrato,
  meioEnvioLinkAssinatura,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      rentabilidade,
      produto,
      aporte,
      dataAporte,
      periodo,
      meioEnvioLinkAssinatura,
    };
    Axios.post(
      `/contrato/confirmarPreContrato/${idContrato}`,
      JSON.stringify(vo)
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function renovarContrato({
  produto,
  aporte,
  periodo,
  rentabilidade,
  contrato,
  meioEnvioLinkAssinatura,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      produto,
      valorParcial: aporte,
      periodo,
      rentabilidade,
      contratoOrigem: contrato,
      meioEnvioLinkAssinatura,
    };
    Axios.post(`/contrato/renovarContrato`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function reenviarContratoParaAssinatura(idProposta) {
  return new Promise((resolve, reject) => {
    Axios.get(`/contrato/reenviarContratoParaAssinatura/${idProposta}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function relatorioResgateComercial(
  dataInicio,
  dataFim,
  cliente,
  consultor,
  produto,
  page
) {
  return new Promise((resolve, reject) => {
    const vo = { dataInicio, dataFim, cliente, consultor, produto };
    Axios.post(`/contrato/relatorioResgate/${page}`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function historico(idContrato) {
  return new Promise((resolve, reject) => {
    Axios.get(`/contratoStatus/${idContrato}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function linkAssinatura(idContrato) {
  return new Promise((resolve, reject) => {
    Axios.get(`/contrato/linkAssinatura/${idContrato}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function calcular({
  produto,
  aporte,
  dataAporte,
  periodo,
  rentabilidade,
  cliente,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      cliente,
      produto,
      aporte,
      dataAporte,
      periodo,
      rentabilidade,
    };

    Axios.post(`/contrato/calcular`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function confirmarDeposito({ dataAporte, contrato, notificar }) {
  return new Promise((resolve, reject) => {
    contrato.dataAporte = dataAporte;

    Axios.post(`/contrato/confirmarDeposito?notificar=${!!notificar}`, contrato)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function anteciparContrato({
  idContrato,
  file,
  tipoResgate,
  rentabilidade,
  produto,
  periodo,
  meioEnvioLinkAssinatura,
  motivoAntecipacao,
  valorSaqueParcial,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      tipoResgate,
      rentabilidade,
      produto,
      periodo,
      meioEnvioLinkAssinatura,
      motivoAntecipacao,
      valorSaqueParcial,
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("contratoAntecipacao", JSON.stringify(vo));

    return Axios.post(`/contrato/antecipar/${idContrato}`, formData, {
      headers: { "content-type": "multipart/form-data;" },
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function inserirComprovante({ idContrato, file, tipo }) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("tipo", tipo);

    return Axios.post(`/contrato/inserirComprovante/${idContrato}`, formData, {
      headers: { "content-type": "multipart/form-data;" },
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function negarContrato({ idContrato, motivoRejeicao }) {
  return new Promise((resolve, reject) => {
    const vo = { idContrato, motivoAntecipacao: motivoRejeicao };

    Axios.post(`/contrato/negarContrato`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function cancelarAntecipacao({ idContrato }) {
  return new Promise((resolve, reject) => {
    const vo = { idContrato };

    Axios.post(`/contrato/cancelarAntecipacao`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function alterarDataAntecipacao({ idContrato, dataPagamentoAntecipacao }) {
  return new Promise((resolve, reject) => {
    const vo = { idContrato, dataPagamentoAntecipacao };
    Axios.post(`/contrato/alterarDataAntecipacao`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function encerrar({ idContrato }) {
  return new Promise((resolve, reject) => {
    const vo = { idContrato };

    Axios.post(`/contrato/encerrar`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function rescindir({ idContrato }) {
  return new Promise((resolve, reject) => {
    const vo = { idContrato };

    Axios.post(`/contrato/rescindir`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function estornar({ idContrato }) {
  return new Promise((resolve, reject) => {
    const vo = { idContrato };

    Axios.post(`/contrato/estornar`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function contratosAVencer({
  produto,
  consultor,
  dataInicio,
  dataFim,
  page = 0,
}) {
  return new Promise((resolve, reject) => {
    const vo = { produto, consultor, dataInicio, dataFim };

    Axios.post(`/contrato/contratosAVencer/${page}`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function contratosAVencerTotais({ produto, consultor, dataInicio, dataFim }) {
  return new Promise((resolve, reject) => {
    const vo = { produto, consultor, dataInicio, dataFim };

    Axios.post(`/contrato/contratosAVencerTotais`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function antecipacaoResgate({
  produto,
  consultor,
  dataInicio,
  dataFim,
  page = 0,
}) {
  return new Promise((resolve, reject) => {
    const vo = { produto, consultor, dataInicio, dataFim };

    Axios.post(`/contrato/antecipacaoResgate/${page}`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function calcularValoresAntecipacao({ contrato }) {
  return new Promise((resolve, reject) => {
    Axios.post(`/contrato/calcularValoresAntecipacao`, contrato)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function dashboard({ dataInicio, dataFim }) {
  return new Promise((resolve, reject) => {
    const vo = { dataInicio, dataFim };
    Axios.post(`/contrato/dashboard`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function dashboardRelatorio({ dataInicio, dataFim, consultor }) {
  return new Promise((resolve, reject) => {
    const vo = { dataInicio, dataFim, consultor };
    Axios.post(`/contrato/dashboardRelatorio`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function confirmarPagamentoAntecipacaoResgate({
  file,
  tipoArquivo,
  idContrato,
  dataPagamento,
}) {
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
  }
  if (tipoArquivo) {
    formData.append("tipoDocumento", tipoArquivo);
  }
  formData.append("dataPagamento", dataPagamento);

  return Axios.post(
    `contrato/confirmarPagamentoAntecipacaoResgate/${idContrato}`,
    formData,
    {
      headers: { "content-type": "multipart/form-data;" },
    }
  );
}

function confirmarPagamentoResgateCnab({ idContrato, aprovaResgateCnab }) {
  return Axios.post(
    `contrato/confirmarPagamentoAutomaticoCnabAntecipacaoResgate/${idContrato}/${aprovaResgateCnab}`
  );
}

function baixarDividendo({
  dataPagamentoDividendo,
  valorDividendoPago,
  idDividendo,
  file,
  motivoAlteracao,
}) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("dataPagamentoDividendo", dataPagamentoDividendo);
    formData.append("valorDividendoPago", valorDividendoPago);
    formData.append("motivoAlteracao", motivoAlteracao);

    Axios.post(`/contrato/baixaDividendo/${idDividendo}`, formData, {
      headers: { "content-type": "multipart/form-data;" },
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function unificarContratos({
  cliente,
  produto,
  aporte,
  dataAporte,
  periodo,
  rentabilidade,
  meioEnvioLinkAssinatura,
  contratos,
  segmento,
}) {
  return new Promise((resolve, reject) => {
    Axios.post(`/contrato/unificarContratos`, {
      cliente,
      produto,
      aporte,
      dataAporte,
      periodo,
      rentabilidade,
      meioEnvioLinkAssinatura,
      contratos,
      segmento,
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarContratosUnificacao({ cliente, segmento }) {
  return new Promise((resolve, reject) => {
    Axios.post(`/contrato/buscarContratosUnificacao`, { cliente, segmento })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function validaProduto(produto) {
  return new Promise((resolve, reject) => {
    Axios.post(`/contrato/validaProduto`, produto)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function reprocessarDividendos({ idContrato }) {
  var vo = { idContrato };
  return new Promise((resolve, reject) => {
    Axios.post(`/contrato/reprocessarDividendos`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const ContratoService = {
  unificarContratos,
  contratosAVencer,
  contratosAVencerTotais,
  baixarDividendo,
  buscarContratosUnificacao,
  validaProduto,
  buscarContratoTotais,
  historico,
  dashboard,
  calcularValoresAntecipacao,
  antecipacaoResgate,
  inserirComprovante,
  negarContrato,
  cancelarAntecipacao,
  anteciparContrato,
  confirmarDeposito,
  calcular,
  relatorioResgateComercial,
  confirmarPreContrato,
  dashboardRelatorio,
  encerrar,
  rescindir,
  estornar,
  buscarContrato,
  linkAssinatura,
  find,
  contratoVencimento,
  createContrato,
  confirmarPagamentoAntecipacaoResgate,
  confirmarPagamentoResgateCnab,
  reenviarContratoParaAssinatura,
  renovarContrato,
  downloadContratoAssinado,
  preContrato,
  reprocessarDividendos,
  alterarDataAntecipacao,
};

export default ContratoService;
